import * as React from 'react';
import * as constants from '../constants';
import crawlingChorusCover from '../resources/images/albumCover.png';
import debuggerCover from '../resources/images/debuggerCover.png';
import newMexicoCover from '../resources/images/newMexicoCover.png';

function App() {
  return (
    <div className='layout'>
      <header>
        <h1>RIPSHIP</h1>
      </header>
      <div className='content'>
        <h2>CRAWLING CHORUS</h2>
        <AlbumPreorder />
        <h2>UPCOMING SHOWS</h2>
        <LinkBlock
          mainText='FEB 8TH - WLG - SAN FRAN'
          subText='Album Release w/ Wiri Donna and Sleeping Village Quartet'
          href={constants.WLG_GIG}
        />
        <LinkBlock
          mainText='FEB 15TH - AKL - WHAMMY BAR'
          subText='Album Release w/ Mullbrain (more support TBA)'
          href={constants.AKL_GIG}
        />
        <LinkBlock
          mainText='FEB 28TH - DUN - CROWN HOTEL'
          subText="Album Release w/ Dale Kerrigan and Bunchy's Big Score"
          href={constants.DUN_GIG}
        />
        <h2>RELEASES</h2>
        <ReleaseBlock
          mainText='DEBUGGER'
          subText='SINGLE OUT NOW'
          spotifyHref={constants.DEBUG_SPOTIFY}
          bandcampHref={constants.DEBUG_BANDCAMP}
          youtubeHref={constants.DEBUG_YOUTUBE}
          rightContent={
            <img
              alt='the debugger single cover, a woman wearing a gas mask holds a can of fly spray to the camera'
              src={debuggerCover}
            />
          }
        />
        <ReleaseBlock
          mainText='NEW MEXICO'
          subText='SINGLE OUT NOW'
          spotifyHref={constants.NEWMEX_SPOTIFY}
          bandcampHref={constants.NEWMEX_BANDCAMP}
          youtubeHref={constants.NEWMEX_YOUTUBE}
          rightContent={
            <img
              alt='the new mexico single cover, the two band members pose atop a rocky mountain'
              src={newMexicoCover}
            />
          }
        />
        <h2>SOCIAL LINKS</h2>
        <section className='socialMediaWrapper'>
          <LinkBlock mainText='instagram' href={constants.instagramPath} />
          <LinkBlock mainText='spotify' href={constants.spotifyPath} />
          <LinkBlock mainText='bandcamp' href={constants.bandcampPath} />
          <LinkBlock mainText='tiktok' href={constants.tiktokPath} />
          <LinkBlock mainText='youtube' href={constants.youtubePath} />
          <LinkBlock mainText='facebook' href={constants.facebookPath} />
        </section>
      </div>
    </div>
  );
}

function AlbumPreorder() {
  return (
    <article className='blockWrapper'>
      <div className='blockWrapperLeft divBorder'>
        <span className='mainText'>ALBUM OUT FEB 7TH</span>
        <a
          className='link'
          rel='noreferrer nofollow'
          target='blank'
          href={constants.CRAWLING_CHORUS_PREORDER}
        >
          Preorder vinyl
        </a>
      </div>
      <div className='blockWrapperRight'>
        <img
          alt='the crawling chorus album cover, a sludgy mass of debris with a monsterous face'
          src={crawlingChorusCover}
        />
      </div>
    </article>
  );
}

function LinkBlock(props) {
  return (
    <a
      className='linkBlock divBorder'
      rel='noreferrer nofollow'
      target='blank'
      href={props.href}
    >
      <span className='mainText'>{props.mainText}</span>
      <span className='subText'>{props.subText}</span>
    </a>
  );
}

function ReleaseBlock(props) {
  const links = [
    { linkText: 'spotify', href: props.spotifyHref },
    { linkText: 'youtube', href: props.youtubeHref },
    { linkText: 'bandcamp', href: props.bandcampHref },
  ];

  return (
    <article className='releaseWrapper'>
      <div className='blockWrapper'>
        <div className='blockWrapperLeft divBorder'>
          <h3 className='mainText'>{props.mainText}</h3>
          <span className='subText'>{props.subText}</span>
        </div>
        <div className='blockWrapperRight'>{props.rightContent}</div>
      </div>
      <div className='socialBlockWrapper'>
        {links.map((link) => (
          <a
            rel='noreferrer nofollow'
            target='blank'
            href={link.href}
            className='socialWrapper divBorder'
          >
            {link.linkText}
          </a>
        ))}
      </div>
    </article>
  );
}

export default App;
